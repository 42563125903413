import React, { useMemo, FC } from 'react';

import { Button } from '@material-ui/core';

import { ReactComponent as FacebookIcon } from 'assets/icons/general/facebook.svg';

type Props = {
  label: string;
};

const FacebookLoginButton: FC<Props> = ({ label }) => {
  const href = useMemo(() => {
    const base = 'https://www.facebook.com/dialog/oauth';
    const clientId = process.env.REACT_APP_FB_ID as string;

    const integrityToken = `mm_${10e15 * Math.random()}`;
    localStorage.setItem('fb_integrity_token', integrityToken.toString());

    const url = new URL(base);
    url.searchParams.set('client_id', clientId);
    url.searchParams.set('redirect_uri', 'https://app.mojamatura.edu.pl/auth/fb_login');
    url.searchParams.set('response_type', 'token');
    url.searchParams.set('state', `{integrity_token=${integrityToken}`);
    url.searchParams.set('scope', 'email,public_profile');

    return url.toString();
  }, []);

  return (
    <Button variant='contained' component='a' href={href} size='large' startIcon={<FacebookIcon width={18} />}>
      {label}
    </Button>
  );
};

export default FacebookLoginButton;
