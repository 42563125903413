import React, { useRef, useState } from 'react';

import { Button, Dialog } from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';

import usersApi from 'api/rest/users/users';
import FormTextInput from 'components/_forms/FormTextInput/FormTextInput';
import FormWrapper from 'components/FormWrapper/FormWrapper';
import GeneralError from 'components/GeneralError/GeneralError';
import LoaderButton from 'components/LoaderButton/LoaderButton';
import SeparatorWithLabel from 'components/SeparatorWithLabel/SeparatorWithLabel';
import MUTATION from 'constants/mutationsKeys';
import PATHS from 'constants/paths';
import { useAuthContext } from 'context/AuthContext';
import { useUserLogContext } from 'context/UserLogContext/UserLogContext';
import CONTAINERS from 'styles/constants/containers';
import AuthGrid from 'uniqueComponents/Auth/AuthGrid/AuthGrid';
import RemindPasswordForm from 'uniqueComponents/Auth/RemindPasswordForm/RemindPasswordForm';
import logError from 'utils/logError/logError';
import emailRegex from 'utils/regex/email';

import FacebookLoginButton from '../../components/FacebookLoginButton/FacebookLoginButton';

type LoginFormInput = {
  email: string;
  password: string;
};

const LoginPage = () => {
  const recaptchaRef = useRef<any>(null);
  const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY as string;
  const { logEvent } = useUserLogContext();
  const [generalError, setGeneralError] = useState<string | null>(null);

  const [forgotPasswordDialogOpen, setForgotPasswordDialogOpen] = useState<boolean>(false);
  const closeForgotDialog = () => setForgotPasswordDialogOpen(false);
  const openForgotDialog = () => {
    logEvent('forgotten-password-click');
    setForgotPasswordDialogOpen(true);
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { onLogin } = useAuthContext();

  const loginMutation = useMutation(MUTATION.LOGIN_BY_EMAIL, usersApi.loginByEmail(), {
    onSuccess: result => {
      // on success
      if ('access' in result) {
        logEvent('login-email');
        recaptchaRef.current.reset();
        onLogin(result.access);
      }
      // on incorrect credentials
      if ('message' in result) {
        recaptchaRef.current.reset();
        setGeneralError(result.message);
      }
    },
  });

  const { control, handleSubmit, watch } = useForm<LoginFormInput>();
  const emailValue = watch('email');

  const onSubmit: SubmitHandler<LoginFormInput> = async data => {
    let recaptcha;
    try {
      recaptcha = await recaptchaRef.current.executeAsync();
    } catch (error) {
      setGeneralError('Wystąpił problem z połączeniem, zgłoś to nam!');
      logError(error as Error, 'Login form');
    }
    loginMutation.mutate({ ...data, recaptcha });
  };

  return (
    <>
      <AuthGrid title='Miło Cię widzieć!'>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <FormWrapper size={CONTAINERS.FORM_M}>
            <FormTextInput
              rules={{ pattern: { value: emailRegex, message: 'To nie jest poprawny e-mail!' } }}
              required
              inputProps={{ type: 'email' }}
              name='email'
              label='Email'
              control={control}
            />
            <FormTextInput required name='password' label='Hasło' control={control} inputProps={{ type: 'password' }} />
          </FormWrapper>
          <FormWrapper size={CONTAINERS.FORM_S}>
            <LoaderButton isLoading={loginMutation.isLoading}>
              <Button variant='contained' color='primary' size='large' type='submit'>
                Zaloguj się
              </Button>
            </LoaderButton>
            {generalError && <GeneralError>{generalError}</GeneralError>}
            <FacebookLoginButton label='Użyj konta Facebook' />
            <Button variant='contained' component={Link} size='large' to={PATHS.LOGIN_BY_SCHOOL}>
              Konto szkolne
            </Button>
            <SeparatorWithLabel>Lub:</SeparatorWithLabel>
            <Button variant='contained' component={Link} size='large' to={PATHS.REGISTER}>
              Zarejestruj się
            </Button>
            <Button variant='contained' onClick={openForgotDialog} size='large'>
              Przypomij hasło
            </Button>
            <ReCAPTCHA size='invisible' ref={recaptchaRef} sitekey={recaptchaSiteKey} />
          </FormWrapper>
        </form>
      </AuthGrid>
      <Dialog onClose={closeForgotDialog} open={forgotPasswordDialogOpen}>
        <RemindPasswordForm redirectUrl={PATHS.LOGIN} initialValue={emailValue} onClose={closeForgotDialog} />
      </Dialog>
    </>
  );
};

export default LoginPage;
