import React, { useEffect } from 'react';

import { useMutation } from '@apollo/react-hooks';
import { CircularProgress } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

import SOCIAL_AUTH from '../../api/graphql/mutations/user/SOCIAL_AUTH';
import messages from '../../constants/dictionaries/messages';
import PATHS from '../../constants/paths';
import { useAuthContext } from '../../context/AuthContext';
import { useUserLogContext } from '../../context/UserLogContext/UserLogContext';
import useCommitError from '../../services/common/useCommitError/useCommitError';
import useSafeMutation from '../../services/common/useSafeMutation/useSafeMutation';

const FacebookLoginCallback = () => {
  const { logEvent } = useUserLogContext();
  const [loginSocialMutation] = useMutation(SOCIAL_AUTH);
  // @ts-ignore
  const { onLogin } = useAuthContext();
  const commitError = useCommitError();
  const safeMutation = useSafeMutation();
  const navigate = useNavigate();

  const handleRoute = async () => {
    const { hash } = window.location;
    if (hash) {
      const params = new URLSearchParams(hash.substring(1));

      const accessToken = params.get('access_token');
      const integrityToken = params.get('state')?.split('=')[1].replace('}', '');
      const integrityCheck = !!integrityToken && localStorage.getItem('fb_integrity_token') === integrityToken;

      console.log(integrityCheck);

      const variables = {
        accessToken,
        provider: 'facebook',
      };

      // @ts-ignore
      const { data } = await safeMutation(
        () => loginSocialMutation({ variables }),
        'Nie udało się utworzyć konta przy pomocy facebooka. Spróbuj za pomocą email.',
      );
      if (data.socialAuth.token) {
        logEvent('login-fb');
        onLogin(data.socialAuth.token);
      } else {
        navigate(PATHS.ROOT);
        commitError({ message: 'Facebook login sync issue', props: window.location }, messages.ERROR.LOGIN(), 'useFacebookAuth');
      }
    } else {
      navigate(PATHS.ROOT);
      commitError({ message: 'Facebook login issue', props: window.location }, messages.ERROR.LOGIN(), 'useFacebookAuth');
    }
  };

  useEffect(() => {
    handleRoute();
  }, []);

  return <CircularProgress size={60} />;
};

export default FacebookLoginCallback;
