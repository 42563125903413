// TODO: move it to theme, reduce it!!!

import theme from './themes/theme';

export default {
  '@global': {
    // GENERAL
    '*': {
      scrollBehavior: 'smooth',

      // SCROLLBARS
      scrollbarColor: 'rgba(180, 180, 180, 0.25) transparent',
      scrollbarWidth: 'thin',
    },
    html: {
      overscrollBehaviorY: 'none',
    },
    body: {
      overflowX: 'hidden',
      background: theme.palette.grey[100],
      overscrollBehaviorY: 'none',
    },
    a: {
      textDecoration: 'none',
    },

    // INPUTS
    '.MuiOutlinedInput-root': {
      borderRadius: 0,
      background: '#fff',
    },
    '.MuiFormControl-root': {
      width: '100%',
    },
    '.MuiOutlinedInput-colorSecondary.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
    'input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    'input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },

    // PAPER
    '.MuiDialog-paperWidthSm.MuiDialog-paperScrollPaper': {
      maxWidth: '800px',
    },
    '.MuiDialog-paper:not(.MuiDialog-paperFullScreen)': {
      background: '#f5f5f5',
      margin: '18px',
    },
    '.MuiDialog-paper': {
      borderRadius: 0,
      backgroundColor: '#f5f5f5',
    },

    // BUTTONS
    '.MuiButton-outlinedSecondary': {
      borderColor: '#fff',
    },
    '.MuiButton-containedSecondary': {
      color: '#fff',
    },
    // SCROLLBARS
    '*::-webkit-scrollbar': {
      width: '0.4rem',
      height: '0.4rem',
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '*::-webkit-scrollbar-thumb': {
      borderRadius: 4,
      backgroundColor: 'rgba(180, 180, 180, 0.25)',
    },

    // PRINT
    '@media print': {
      body: {
        border: '1px solid white',
        height: '99%',
        pageBreakAfter: 'avoid',
        pageBreakBefore: 'avoid',
      },
      html: {
        border: '1px solid white',
        height: '99%',
        pageBreakAfter: 'avoid',
        pageBreakBefore: 'avoid',
      },
    },

    //   Recaptcha hide
    '.grecaptcha-badge': {
      visibility: 'hidden !important',
    },
  },
};
