import ClassroomExercisesPage from 'pages/ClassroomExercisesPage/ClassroomExercisesPage';
import ClassroomPage from 'pages/ClassroomPage/ClassroomPage';
import DashboardPage from 'pages/DashboardPage/DashboardPage';
import Exercises from 'pages/Exercises/Exercises';
import ExercisesSetsPage from 'pages/ExercisesSetsPage/ExercisesSetsPage';
import FacebookLoginCallback from 'pages/FacebookLoginCallback/FacebookLoginCallback';
import HelpPage from 'pages/HelpPage/HelpPage';
import LoginBySchoolPage from 'pages/LoginBySchoolPage/LoginBySchoolPage';
import LoginPage from 'pages/LoginPage/LoginPage';
import Page404 from 'pages/Page404/Page404';
import PasswordResetPage from 'pages/PasswordResetPage/PasswordResetPage';
import Register from 'pages/Register/Register';
import RegisterByMail from 'pages/RegisterByMail/RegisterByMail';
import Sheets from 'pages/Sheets/Sheets';
import SubjectSelect from 'pages/SubjectSelect/SubjectSelect';
import TeacherDashboardPage from 'pages/TeacherDashboardPage/TeacherDashboardPage';
import UserSettingsPage from 'pages/UserSettingsPage/UserSettingsPage';
import TeacherLayout from 'root/TeacherLayout/TeacherLayout';

import PATHS, { TEACHER_PREFIX } from './paths';

type Route = {
  path: string;
  element: React.FC;
  transition?: boolean;
  index?: boolean;
  onlyForPremiumUser?: boolean;
  name: string;
};

export type NestedRoute = Route & {
  subRoutes?: Route[];
};

const authRoutes: NestedRoute[] = [
  {
    path: PATHS.LOGIN,
    element: LoginPage,
    transition: true,
    name: 'Zaloguj się',
  },
  {
    path: PATHS.LOGIN_BY_SCHOOL,
    element: LoginBySchoolPage,
    transition: true,
    name: 'Zaloguj na konto szkolne',
  },
  {
    path: PATHS.REGISTER,
    element: Register,
    transition: true,
    name: 'Rejestracja',
  },
  {
    path: PATHS.FB_CALLBACK,
    element: FacebookLoginCallback,
    transition: true,
    name: 'Logowanie przez Facebook',
  },
  {
    path: PATHS.REGISTER_BY_MAIL,
    element: RegisterByMail,
    transition: true,
    name: 'Rejestracja przez email',
  },
  {
    path: PATHS.PASSWORD_RESET,
    element: PasswordResetPage,
    transition: true,
    name: 'Resetuj hasło',
  },
  {
    path: '*',
    element: Page404,
    transition: true,
    name: '404',
  },
];

const internalRoutes: NestedRoute[] = [
  {
    path: PATHS.ROOT,
    element: DashboardPage,
    transition: true,
    name: 'Menu główne',
  },
  {
    path: PATHS.EXERCISES,
    element: Exercises,
    transition: true,
    name: 'Zadania',
  },
  {
    path: PATHS.SHEETS,
    element: Sheets,
    transition: true,
    name: 'Arkusze',
  },
  {
    path: PATHS.EXERCISES_SETS,
    element: ExercisesSetsPage,
    transition: true,
    name: 'Zestawy zadań',
    onlyForPremiumUser: true,
  },
  {
    path: PATHS.USER_SETTINGS,
    element: UserSettingsPage,
    transition: true,
    name: 'Ustawienia',
  },
  {
    path: `${PATHS.USER_SETTINGS}/:dialogToOpen`,
    element: UserSettingsPage,
    transition: true,
    name: 'Ustawienia',
  },
  {
    path: PATHS.SUBJECT_SELECT,
    element: SubjectSelect,
    transition: false,
    name: 'Wybierz przedmiot',
  },
  {
    path: PATHS.HELP,
    element: HelpPage,
    transition: false,
    name: 'Pomoc',
  },
  {
    path: TEACHER_PREFIX,
    element: TeacherLayout,
    transition: false,
    name: 'Panel nauczyciela',
    subRoutes: [
      {
        path: PATHS.TEACHER_DASHBOARD,
        element: TeacherDashboardPage,
        transition: false,
        name: 'Panel nauczyciela',
        index: true,
      },
      {
        path: PATHS.CLASSROOM,
        element: ClassroomPage,
        transition: false,
        name: 'Moi uczniowie',
      },
      {
        path: PATHS.CLASSROOM_EXERCISES,
        element: ClassroomExercisesPage,
        transition: false,
        name: 'Zadania klasy',
      },
      {
        path: '*',
        element: Page404,
        transition: true,
        name: '404',
      },
    ],
  },
];

export { internalRoutes, authRoutes };
